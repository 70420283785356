<template>
  <div>
    <b-breadcrumb :items="breadcrumb" class="my-3"></b-breadcrumb>

    <b-list-group>

      <b-list-group-item v-if="isUser" to="/manufacturing">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="text-primary">Manufacturing</h5>
            <span class="text-muted">Show all supported features under manufacturing section</span>
          </div>
          <div class="col text-right">
            <h1 class="m-0"><b-icon-tools></b-icon-tools></h1>
          </div>
        </div>
      </b-list-group-item>

      <b-list-group-item v-if="isAdmin" :href="apiurl" target="_blank">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="text-primary">API <b-icon-lock-fill class="text-warning"></b-icon-lock-fill></h5>
            <span class="text-muted">Show API endpoint references</span>
          </div>
          <div class="col text-right">
            <h1 class="m-0"><b-icon-code-slash></b-icon-code-slash></h1>
          </div>
        </div>
      </b-list-group-item>

      <b-list-group-item v-if="isAdmin" to="/settings">
        <div class="row align-items-center">
          <div class="col">
            <h5 class="text-primary">Settings <b-icon-lock-fill class="text-warning"></b-icon-lock-fill></h5>
            <span class="text-muted">Show all configurable settings</span>
          </div>
          <div class="col text-right">
            <h1 class="m-0"><b-icon-gear></b-icon-gear></h1>
          </div>
        </div>
      </b-list-group-item>

    </b-list-group>

  </div>
</template>

<script>
import { auth, hasRole } from "../helpers/auth";
import config from '@/helpers/config';

export default {
  name: 'Home',
  data () {
    return {
      tab: '',
      breadcrumb: [
        {
          text: 'Home',
          active: true
        },
      ],
      loadedProduct: null,
      isAdmin: false,
      isUser: false,
      apiurl: '',
    }
  },
  async mounted() {
    this.account = auth.getAccount();
    this.isAdmin = await hasRole(['Manufacturing.Admin'])
    this.isUser = await hasRole(['Manufacturing.User','Manufacturing.Admin'])
    this.apiurl = config.apiUrl.replace('/api','')
  },
}
</script>